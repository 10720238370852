<template>
  <v-dialog
    v-model="dialog"
    eager
    max-width="800px"
    open-delay="300"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-btn
        v-if="hasGlobalReferences && !hideActivator"
        color="primary"
        depressed
        v-on="on"
      >
        <icon-add class="mr-2" size="1.5em" />
        {{ $t("sync.invoice.references") }}
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-card-title class="pa-0">
        <sheet dense depressed light width="100%">
          <v-row>
            <v-col cols="12" lg="6">
              <div
                class="text-h5 pb-4"
                v-text="$t('sync.references.title', { text: sSerial })"
              />
              <form-field-text
                v-model="amount"
                :disabled="!arSelected.length"
                :rules="rules"
                label="amount"
                input-type="number"
                v-bind="$attrs"
              />
            </v-col>

            <v-col class="d-flex justify-end" cols="12" lg="6">
              <v-list class="pa-0" color="transparent" dense>
                <v-list-item>
                  <v-list-item-title v-text="sSerial" />
                  <v-list-item-icon>
                    <icon-text-outline outlined />
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item v-if="obCustomer">
                  <v-list-item-title v-text="obCustomer.name" />
                  <v-list-item-icon>
                    <icon-people-outline />
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <price-viewer
                      :currency="obInvoice.currency"
                      :value="fBalance"
                    />
                  </v-list-item-title>
                  <v-list-item-icon>
                    <icon-dollar-sign />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </sheet>
      </v-card-title>

      <v-card-text>
        <v-data-table
          v-model="arSelected"
          :headers="headers"
          :items="items"
          :loading="loading"
          selectable-key="can_referenced"
          show-select
          single-select
          @item-selected="onSelectItem"
        >
          <template #[`item.created_at`]="{ item }">
            {{ $dayjs(item.created_at).format("L LT") }}
          </template>

          <template #[`item.total_price_value`]="{ item }">
            <price-viewer
              :currency="item.currency"
              :label="true"
              :value="item.total_price_value"
              chip
            />
          </template>

          <template #[`item.balance_value`]="{ item }">
            <price-viewer
              :currency="item.currency"
              :label="true"
              :value="item.balance_value"
              chip
            />
          </template>

          <template #[`item.order_number`]="{ item }">
            <span class="font-weight-bold" v-text="item.order_serial" />
            <span class="ml-2" v-text="item.order_number" />
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!isValid || loading"
          color="primary"
          text
          @click="apply"
        >
          {{ $t("apply") }}
        </v-btn>
        <v-btn :disabled="loading" color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import CacheMixin from "@/mixins/CacheMixin";
import {
  FirmData,
  Invoice,
  InvoiceCollection,
  InvoiceData,
  InvoiceMovementType,
} from "@planetadeleste/vue-mc-gw";
import { number } from "mathjs";

import PriceViewer from "@/components/common/PriceViewer.vue";
import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";
import { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { delay } from "lodash";
import { InvoiceModule } from "@/store/invoice";

@Component({
  components: { PriceViewer, CustomerPreview },
})
export default class ReferencesSyncDialog extends Mixins(
  DataTableMixin,
  CacheMixin
) {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly invoice!: Invoice | Partial<InvoiceData>;
  @Prop(Boolean) readonly hideActivator!: boolean;

  amount = 0;
  loading = false;
  sMovementCode: number | null = null;
  arSelected: Partial<InvoiceData>[] = [];
  obFilters: Record<string, any> = {};
  obCollection: InvoiceCollection = new InvoiceCollection();

  get rules() {
    const obRules: Record<string, any> = { required: true };

    if (this.fBalance) {
      obRules.double = true;
      obRules.max_value = this.fBalance;
      obRules.min_value = 1;
    }

    if (this.fSelectedBalance && this.fSelectedBalance < this.fBalance) {
      obRules.max_value = this.fSelectedBalance;
    }

    return obRules;
  }

  get isValid() {
    if (
      !this.amount ||
      this.amount < 0 ||
      !this.fBalance ||
      !this.arSelected.length
    ) {
      return false;
    }

    if (this.rules.max_value && this.amount > this.rules.max_value) {
      return false;
    }

    return true;
  }

  get items(): InvoiceData[] | Record<string, any> {
    return this.obCollection.length ? this.obCollection.getModelList() : [];
  }

  get obInvoice() {
    const obInvoiceData = this.invoice || {};

    return obInvoiceData instanceof Invoice
      ? obInvoiceData
      : new Invoice(obInvoiceData);
  }

  get obSelectedInvoice() {
    const obData = this.arSelected.length ? this.arSelected[0] : {};

    return new Invoice(obData);
  }

  get hasGlobalReferences(): boolean {
    return this.obInvoice.get("has_global_references", false);
  }

  get iMovementType(): number {
    return number(this.obInvoice.get("invoice_movement_type.code", 0));
  }

  get signed(): boolean {
    return this.obInvoice.get("is_signed", false) || this.signing;
  }

  // GETTERS

  get signing(): boolean {
    return (
      !!InvoiceModule.signing.length &&
      !!this.obInvoice.id &&
      InvoiceModule.signing.includes(this.obInvoice.id)
    );
  }

  get fBalance(): number {
    return this.obInvoice ? this.obInvoice.get("balance_value") : 0;
  }

  get fSelectedBalance(): number {
    return this.obSelectedInvoice.get("balance_value", 0);
  }

  get sSerial() {
    return this.obInvoice
      ? `${this.obInvoice.order_serial} ${this.obInvoice.order_number}`
      : null;
  }

  /**
   * Invoice movement type is DEBIT NOTE
   * @returns {boolean}
   */
  get bIsDN(): boolean {
    return this.iMovementType === InvoiceMovementType.CODE_DEBIT_NOTE;
  }

  get obCustomer(): Partial<FirmData> {
    return this.obInvoice.customer ? this.obInvoice.customer.firm : {};
  }

  @Watch("dialog", { immediate: true })
  onOpen(bDialog: boolean) {
    if (!bDialog) {
      return;
    }

    delay(() => {
      this.search();
      this.setTableHeaders();
    }, 500);
  }

  async mounted() {
    this.setTableHeaders();
  }

  setTableHeaders() {
    const arHeaders: DataTableHeader[] = [
      { text: "date", value: "created_at" },
      {
        text: "invoice.dgi.number",
        value: "order_number",
        sortable: false,
      },
      { text: "invoice.type", value: "invoice_type.name", sortable: false },
      {
        text: "total.invoice",
        value: this.bIsDN ? "total_price_value" : "balance_value",
      },
    ];
    this.setDTHeaders(arHeaders);
  }

  close() {
    this.$emit("input", false);
    this.arSelected = [];
    this.obFilters = {};
    this.obCollection = new InvoiceCollection();
    this.sMovementCode = null;
  }

  async apply() {
    if (!this.isValid || !this.arSelected.length) {
      return;
    }

    // Set request data
    const obData: Record<string, any> = {
      amount: this.amount,
      invoice_id: this.obInvoice.id,
      ref_id: this.obSelectedInvoice.id,
    };
    // Set axios config to use a custom route request on invoice model
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.set_balance", obData),
      method: "POST",
      data: obData,
    };
    const obInvoice = new Invoice();
    const obResponse = await obInvoice.createRequest(obConfig).send();
    if (obResponse.getData()) {
      const obResponseData: Partial<InvoiceData> = obResponse.getData().data;
      this.obInvoice.set(obResponseData);
    }

    // Find reference in collection
    // load updated reference model and set properties
    // clear selection
    const obSelectedModel = this.obCollection.find({
      id: this.obSelectedInvoice.id,
    });
    if (obSelectedModel) {
      const obInvoiceTemporary = new Invoice({ id: this.obSelectedInvoice.id });
      await obInvoiceTemporary.fetch();
      obSelectedModel.set(
        "balance_value",
        obInvoiceTemporary.get("balance_value")
      );
      obSelectedModel.set(
        "can_referenced",
        obInvoiceTemporary.get("can_referenced")
      );
      this.arSelected = [];
    }
  }

  onSelectItem() {
    delay(() => {
      if (this.fSelectedBalance && this.fSelectedBalance < this.fBalance) {
        this.amount = this.fSelectedBalance;
      } else {
        this.amount = this.fBalance;
      }
    }, 300);
  }

  async search() {
    try {
      if (!this.obInvoice || !this.obInvoice.customer_id || !this.dialog) {
        return;
      }

      this.loading = true;
      this.obFilters.customer = this.obInvoice.customer_id;
      this.obFilters.signed = 1;
      this.obFilters.no_check_skip_balance = 1;

      if (this.obInvoice.currency_id) {
        this.obFilters.currency = this.obInvoice.currency_id;
      }

      // Check by invoice movement type
      const invoiceMovementTypeId = this.obInvoice.invoice_movement_type_id;
      if (invoiceMovementTypeId || this.sMovementCode) {
        if (!this.sMovementCode) {
          this.sMovementCode = await this.getMovementTypeCode(
            invoiceMovementTypeId
          );
        }

        switch (this.sMovementCode) {
          case InvoiceMovementType.CODE_DEBIT_NOTE:
            this.obFilters.invoiceMovementType = [
              InvoiceMovementType.CODE_REFOUND,
            ];
            break;

          case InvoiceMovementType.CODE_REFOUND:
            this.obFilters.unpaid = 1;
            this.obFilters.invoiceMovementType = [
              InvoiceMovementType.CODE_SALES,
            ];
            break;
        }
      }

      // Clear collection filters and models
      this.obCollection.clearFilters().clear();

      // Fetch collection using list method (without pagination)
      await this.obCollection.filterBy(this.obFilters).list();

      if (this.bIsDN) {
        this.obCollection.each((obModel) => {
          if (obModel.total_price_value) {
            obModel.set("can_referenced", true);
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  async getMovementTypeCode(invoiceMovementTypeId: number) {
    const obInvoiceMovementTypeData = this.cache(
      invoiceMovementTypeId,
      "InvoiceMovementType"
    );
    const obInvoiceMovementType = new InvoiceMovementType();

    if (obInvoiceMovementTypeData) {
      obInvoiceMovementType.set(obInvoiceMovementTypeData);
    } else {
      obInvoiceMovementType.set("id", invoiceMovementTypeId);
      await obInvoiceMovementType.fetch();
    }

    return obInvoiceMovementType.code
      ? number(obInvoiceMovementType.code)
      : null;
  }
}
</script>
